export function headerFunc() {
  // ハンバーガーメニュー実装
  $('.navToggle').on('click', function() {
    $('.navToggle').toggleClass('closeNav');
    $('.headerNav').toggleClass('openNav');
  });

  $('.headerNavList a').on('click', function() {
    $('.headerNav').removeClass('openNav');
    $('.navToggle').removeClass('closeNav');
    $('body').removeClass('menuOpen');
  });
}

export function headerScrFunc() {
  console.log('スクロールで呼ばれます。');
}