import Swiper from "swiper";

//トップページ　キービジュアルスライダー
export function idxFunc() {
  // const swiper = new Swiper('.swiper', {
  //   speed: 1000,
  //   loop: true,
  //   centeredSlides: true,
  //   autoplay: {
  //     delay: 3000,
  //   },
  //   // pagination: {
  //   //   el: ".swiper-pagination",
  //   //   clickable: true
  //   // },
  //   // navigation: {
  //   //   nextEl: '.swiper-button-next',
  //   //   prevEl: '.swiper-button-prev'
  //   // },
  //   slidesPerView: 'auto',
  //   spaceBetween: 14,  
  //   breakpoints: {
  //     768: {
  //       spaceBetween: 45,  
  //     }
  //   }
  // });

  $('#btn-more').on('click', function() {
    $('#text-more').addClass('open');
    $(this).addClass('open');
  });

  $('.menu dt').on('click', function() {
    $(this).next('dd').slideToggle();
    $(this).toggleClass('open');
  });
}
